import { IoMdClose } from 'react-icons/io';
import { NewsList } from '../../molecules/newsList';
import { CloseButton, SaveButton } from './style';
import { useState } from 'react';
import { useContext } from 'react';
import { ContextAplication } from '../../../context';
import { GenericModalComponent } from '../../atoms/genericModal';
import { LoadingComponent } from '../../atoms/loadingComponent';

export function NewVersionModal({ onClose, onClickToGenerateNewVersion }) {
  const { handleCreate } = useContext(ContextAplication);

  const [confirmModal, setConfirmModal] = useState(false);

  const [newsCards, setNewsCards] = useState([]);
  const [comingSoonCards, setComingSoonCards] = useState([]);
  const [loading, setLoading] = useState(false)

  function handleClose() {
    onClose();
  }

  async function handleSubmitVersionReleaseNotes(version) {

    try {
      let parsedNewsCards = newsCards.map((card) => {
        
        if(card.link?.includes('calculators')) {
          console.log("link inclui calculators")
          return {
            title: card.title,
            description: card.description,
            type: 'update',
            version: version.version,
            sub_version: version.sub_version,
            is_home: false,
            link: card.link,
            codepush_android: card.calcSelected.codepush_android,
            codepush_ios: card.calcSelected.codepush_ios,
          };
        }
        return {
          title: card.title,
          description: card.description,
          type: 'update',
          version: version.version,
          sub_version: version.sub_version,
          is_home: false,
          link: card.link,

        };
      });
      let parsedComingSoonCards = comingSoonCards.map((card) => {
        return {
          description: card.description,
          type: 'upcoming',
          version: version.version,
          sub_version: version.sub_version,
        };
      });

      const concat = [...parsedNewsCards, ...parsedComingSoonCards];

      const response = await handleCreate(
        'version/v2/release-note/create',
        concat
      );
    } catch (error) {
      console.log(error, '<< error');
    }
  }

  async function handleNewVersion() {
    try {
      setLoading(true);

      const response = await handleCreate('version/v2/create', {});

        console.log('gerando versao: ', response)
      
      await handleSubmitVersionReleaseNotes(response);

      onClickToGenerateNewVersion(response)
      setConfirmModal(false);
    } catch (error) {
      console.log(error, '<<<');
    }
    setLoading(false);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'flex-start',
        }}
      >
        <h2>Gera nova versão</h2>
        <CloseButton
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 32,
            width: 32,
            padding: 0,
          }}
          onClick={handleClose}
        >
          <IoMdClose style={{ height: 24, width: 24 }} />
        </CloseButton>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'flex-start',
        }}
      >
        <h2>Notas sobre a atualização</h2>
      </div>
      <NewsList
        newsCards={newsCards}
        setNewsCards={setNewsCards}
        comingSoonCards={comingSoonCards}
        setComingSoonCards={setComingSoonCards}
      />

      <SaveButton onClick={() => setConfirmModal(true)}>
        Gerar versão
      </SaveButton>

      <GenericModalComponent
        key={'modal_confirm_new_version'}
        isOpen={confirmModal}
        title={'Deseja realmente gerar uma nova versão?'}
        onCloseButton={() => setConfirmModal(false)}
        onConfirmButton={() => handleNewVersion()}
        textCloseButton={'CANCELAR'}
        textConfirmButton={'GERAR'}
        children={
          <p>
            <strong>
              Essa ação é irreversível e não pode ser interrompida!
            </strong>
          </p>
        }
      />

      {loading && <LoadingComponent />}
    </>
  );
}
