import axios from 'axios';

const api = axios.create({
  baseURL: 'https://searchapi-dev.blackbook.com.br/',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'origin': 'admin',
    'Authorization' : `672e122324fcfb965883ead2`
  },
});

export default api;
