import { useState } from 'react';
import api from '../../../services/axios-search';
import {
  CloseButton,
  ModalLinkContainer,
  SearchForm,
} from './style';
import { IoMdClose } from 'react-icons/io';
import { SearchComponent } from './SearchComponent';

export function ModalLink({ content, setOpenModalLink, linkCallback }) {
  const [searchResponse, setSearchResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSearch(e) {
    if (!e) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await api.get(`/search/internal`, {
        params: { term: e.split('+').join(':plus_operator:') },
        headers: {
          'Content-Type': 'application/json',
          origin: 'admin',
          Authorization: `672e122324fcfb965883ead2`,
        },
      });
      console.log(response.data);
      setSearchResponse(response.data);
      // return response.data;
    } catch (error) {
      console.log(error);
      // throw {
      //   code: error.response.status,
      //   message: error.response.data.message || error.response.statusText,
      // };
    }
    setIsLoading(false);
  }

  return (
    <ModalLinkContainer>
      <div className="link__modal">
        <div className="modal__header">
          <h3>Adicionar Link</h3>

          <CloseButton
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 32,
              width: 32,
              padding: 0,
            }}
            onClick={() => setOpenModalLink(false)}
          >
            <IoMdClose style={{ height: 24, width: 24 }} />
          </CloseButton>
        </div>

        <SearchForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(e.target.elements.inputHeader.value);
          }}
        >
          <input id="inputHeader" placeholder="Digite o termo para a busca." />
          <button type="submit">Buscar</button>
        </SearchForm>

        <SearchComponent isLoading={isLoading} response={searchResponse} linkCallback={linkCallback} />
      </div>
    </ModalLinkContainer>
  );
}
